<template>
  <div class="login-bgd">
    <CNavbar expandable="md" type="dark" style="background-color:#060706!important;" color="info">
    <CToggler in-navbar @click="collapsed = !collapsed"/>
    <CNavbarBrand href="#"><h1><b>Coprep</b></h1></CNavbarBrand>
    <CNavbarNav class="ml-auto">
      
    </CNavbarNav>
  </CNavbar>
    <CContainer class="d-flex min-vh-100">
      
      <CRow alignVertical="center" alignHorizontal="center" class="flex-grow-1">
        <CCol sm="4" lg="5">
          <CAlert :color="color" :show.sync="currentAlertCounter" closeButton>
            {{ message }}
            <!--CProgress
              :max="10"
              :value="currentAlertCounter"
              height="3px"
              color="danger"
              animate
            /-->
          </CAlert>
          <CCard>
            <CCardBody>
              <CForm @submit.prevent="loginUser()">
                <div class="text-center">
                  <h1>Welcome Back</h1>
                  <p class="text-muted">Sign In to your account</p>
                </div>
                <CInput
                  id="username"
                  placeholder="Username"
                  autocomplete="username email"
                  v-model="username"
                  required
                >
                  <template #prepend-content>
                    <CIcon name="cil-user" />
                  </template>
                </CInput>
                <CInput
                  id="password"
                  placeholder="Password"
                  type="password"
                  v-model="password"
                  required
                >
                  <template #prepend-content>
                    <CIcon name="cil-lock-locked" />
                  </template>
                </CInput>
                <CCol col="12" class="text-center">
                  <CButton type="submit" :disabled="disabled" color="primary" class="px-4"
                    >Get Started</CButton
                  >
                </CCol>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol col=12 
        >*Please make sure to use Firefox/Chrome as default browser
        <br>
          *Please make sure to Allow camera and other permission when asked.
        </CCol>
      </CRow>
        
    </CContainer>
  </div>
</template>

<script>
import { ajaxCallMixin } from "@/mixins/HttpCommon";
import { localDb } from "@/mixins/localDb";

export default {
  name: "Login",
  mixins: [ajaxCallMixin, localDb],
  data() {
    return {
      username: "",
      password: "",
      currentAlertCounter: 0,
      message: "",
      testStatus: {
        0: "Not Attempted",
        1: "Attempted",
        6: "Attempted",
      },
      color: "danger",
      disabled :false
    };
  },
  beforeMount() {},
  methods: {
    loginUser() {
      this.disabled = true;
      var data = {};
      data.username = this.username;
      data.password = this.password;
      var url = "/test/test/testLogin";
      var params = {};
      params.data = data;
      this.ajaxCall(url, params, this.saveExamData);
    },
    saveExamData(apiResponse) {
      
      if (apiResponse.error != true ) {
        // to check if candidate has attempted the test before or not
        let flag = apiResponse.data.testStatus;
        if (flag === null || this.testStatus[flag] === "Not Attempted") {
          this.save("examData", apiResponse.data, "local");
          this.save("testToken", apiResponse.data.authToken, "local");
          this.$router.push("/exam/details");
        } else if (this.testStatus[flag] === "Attempted") {
          this.currentAlertCounter = 5;
          this.message = "You have submitted the test.";
          this.color = "success";
        }
      } else {
        this.currentAlertCounter = 5;
        this.message = "Incorrect credentials please check.";
        this.color = "danger";
      }
      this.disabled = false;
    },
  },
};
</script>

<style scoped>
.login-bgd {
  background-image:url('/img/loginback.jpg');
  background-size: cover;
}
</style>